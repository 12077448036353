import React from 'react'
import { Alert, Spin } from 'antd'
import useFilters from '@hooks/useFilters'
import useConnection from '@hooks/useConnection'
import useActions from '@hooks/useActions'
import modules from '@modules'
import './SurveyCounter.less'
import { SurveysCountInfo } from '@modules/AppDataModule'
import useClientTranslation from 'config/clients/useClientTranslation'
import useClientConfig from 'config/clients/useClientConfig'


/**
* SurveyCounter
*/
const SurveyCounter = (): JSX.Element => {
  const { filters, } = useFilters()
  const { translate } = useClientTranslation()
  const { getSurveysCountersValue } = useClientConfig()
  const {
    surveysCount,
    loadingSurveysCount
  } = useConnection<{
    surveysCount: SurveysCountInfo | null,
    loadingSurveysCount: boolean
  }>([
    'appData.surveysCount',
    'appData.loadingSurveysCount'
  ])

  const dispatchers = useActions(modules.actions.appData, [])

  React.useEffect(() => {
    dispatchers.getSurveyCount({
      filters: filters,
    })
  }, [filters,])

  if (loadingSurveysCount) {
    return <Spin />
  }

  if (surveysCount === null) {
    return <span></span>
  }

  return (
    <div className={'survey-counter-component'}>
      <span>
        <span className='survey-number'>{getSurveysCountersValue(surveysCount)}</span>
        {' ' + translate('surveys.answered_surveys')}
      </span>
    </div>
  )
}

export default SurveyCounter
