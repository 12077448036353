import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_SCALE_3_COLORS, getProfuturoAsesoria, getProfuturoSatisfaccion, commonWidgets, getProfuturoNpsPorMes } from '../commonWidgetsProfuturo'


export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [


    getProfuturoNpsPorMes({
      position: [0, 0],
      size: [1, 2],
      indicator: 'cuestferiaafore_pregunta_11',
      helpText: 'Para finalizar, en una escala del 0 al 10, dónde 0 es nada probable y 10 muy probable, a partir de tu atención en la feria de Afores, ¿qué tan probable es que recomiendes a Profuturo a tus amigos y familiares?',
      extraConfigs: {
        categories: ['promoters', 'passives', 'detractors'],
        useEmojis: true
      },
    }),

    getProfuturoSatisfaccion({
      position: [1, 0],
      size: [1, 2],
      indicator: 'cuestferiaafore_pregunta_3',
      helpText: '¿Qué tan satisfecho estás con tu visita a Profuturo en la Feria de Afores?'
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Atención',
      position: [3, 0],
      size: [2, 2],
      indicator: 'cuestferiaafore_pregunta_10',
      helpText: 'Atención',
      extraConfigs: {
        colors: PRODUTURO_SCALE_3_COLORS,
      }
    }),

    getProfuturoAsesoria({
      position: [0, 2],
      size: [1, 3],
      indicator: 'cuestferiaafore_pregunta_9',
      helpText: '¿Qué te pareció la asesoría recibida?'
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La ubicación del módulo (diseño para identificarlo)',
      position: [1, 2],
      size: [3, 1],
      helpText: 'La ubicación del módulo (diseño para identificarlo)',
      indicator: 'cuestferiaafore_pregunta_6'
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La instalación (limpieza, espacio de espera, espacio de atención temperatura)',
      position: [1, 3],
      size: [3, 1],
      helpText: 'La instalación (limpieza, espacio de espera, espacio de atención temperatura)',
      indicator: 'cuestferiaafore_pregunta_7'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Promocionales (plumas, videos, flyers)',
      position: [1, 3],
      size: [3, 1],
      helpText: 'Promocionales (plumas, videos, flyers)',
      indicator: 'cuestferiaafore_pregunta_8'
    }),
  ]
} as DasboardConfig
