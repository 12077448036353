import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, BYD_PIE_SINO_COLORS, bydAnsweredMapper } from './commonWidgetsByd'
import { bydTooltipTotalEncuestas } from './cxoResume'

export default {
    title: 'Nacional Venta Dashboard',
    description: 'Dashboard de Nacional Venta',
    backgroundClassName: (filters) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI) => [
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'lineDetailedByMonthAndGeo',
            title: 'widgets.snTestDrive',
            position: [0, 0],
            size: [4, 4],
            extraConfigs: {
                backgroundColor: 'transparent',
                theme: 'dark',
            },
            indicator: 'TEST_DRIVE_SATISFACCION',
            criticalMomentInclude: [11251],
        }),


        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'surveyCountTrendByMonth',
            position: [0, 4],
            size: [1, 2],
            indicator: 'SURVEY_COUNT',
            criticalMomentId: 11251,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'surveyCountTrendByWeek',
            position: [1, 4],
            size: [1, 2],
            indicator: 'SURVEY_COUNT',
            criticalMomentId: 11251,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'alertsTrendByMonth',
            subTitle: 'widgets.alertsCountMonth',
            position: [2, 4],
            size: [1, 2],
            indicator: 'ALERT_FIRST_RESPONSE_TIME',
            criticalMomentId: 11251,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'alertsTrendByWeek',
            subTitle: 'widgets.alertsCountWeek',
            position: [3, 4],
            size: [1, 2],
            indicator: 'ALERT_FIRST_RESPONSE_TIME',
            criticalMomentId: 11251,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.snTestDrive',
            position: [0, 6],
            size: [1, 2],
            indicator: 'TEST_DRIVE_SATISFACCION',
            criticalMomentId: 11251,
            extraConfigs: {
                decimals: 1
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'semicircleGeneric',
            title: 'widgets.npsTestDrive',
            position: [1, 6],
            size: [1, 2],
            indicator: 'TEST_DRIVE_NPS',
            criticalMomentId: 11251,
            extraConfigs: {
                decimals: 0
            },
            extras: {
                periods: 6
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.porcRespuestasVenta',
            position: [2, 6],
            size: [1, 2],
            indicator: 'survey-response-counters',
            key: 'testdrive-porc-respuestas-gauge',
            //indicator: 'venta-porc-respuestas-gauge',
            //url: 'survey-responses/values',
            mapper: bydAnsweredMapper,
            tooltipFormatter: bydTooltipTotalEncuestas,
            criticalMomentId: 11251,
            extraConfigs: {
                decimals: 1,
                maxValue: 100,
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.regresariaTestDrive',
            position: [3, 6],
            size: [1, 2],
            indicator: 'TEST_DRIVE_REGRESO',
            criticalMomentId: 11251,
            extraConfigs: {
                decimals: 1
            }
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'widgets.entregaTestDrive',
            position: [0, 8],
            size: [2, 2],
            indicator: 'TEST_DRIVE_ENTREGA',
            helpText: '',
            extraConfigs: {
                itemGap: 6,
                center: ['27%', '55%'],
                colors: BYD_PIE_SINO_COLORS
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'widgets.utilidadTestDrive',
            position: [2, 8],
            size: [2, 2],
            indicator: 'TEST_DRIVE_UTILIDAD',
            helpText: '',
            extraConfigs: {
                itemGap: 6,
                center: ['27%', '55%'],
                colors: BYD_PIE_SINO_COLORS
            }
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'rankingByGeoBest',
            title: 'widgets.bestGeoLocationsVenta',
            position: [0, 10],
            size: [1, 2],
            extraConfigs: {
                backgroundColor: '#501C78',
                titleBackgroundColor: '#88DD76'
            },
            indicator: 'TEST_DRIVE_SATISFACCION',
            criticalMomentId: 11251,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'rankingByGeoWorst',
            title: 'widgets.worstGeoLocationsVenta',
            position: [1, 10],
            size: [1, 2],
            extraConfigs: {
                backgroundColor: '#501C78',
                titleBackgroundColor: '#db7476'
            },
            indicator: 'TEST_DRIVE_SATISFACCION',
            criticalMomentId: 11251,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'mapByGeos',
            title: 'Mapa de estaciones',
            position: [2, 10],
            size: [2, 2],
            indicator: 'TEST_DRIVE_SATISFACCION',
            criticalMomentInclude: [11251],
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'Análisis de sentimientos',
            indicator: 'SENTIMENT',
            criticalMomentId: 11251,
            position: [0, 12],
            size: [4, 3],
        }),

    ],
} as DasboardConfig
