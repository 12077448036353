import { answeredMapper, invalidMapper } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, } from './commonWidgetsMitsu'

export default {
  title: 'Nacional Reparación Dashboard',
  description: 'Dashboard de Nacional Reparación',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'REPARACION_SATISFACCION',
      criticalMomentInclude: [3]
    }),
    //1
    {
      title: 'Encuestas',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'Alertas',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'survey-response-counters',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'survey-response-counters',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsTrendByMonth',
      position: [2, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsTrendByWeek',
      position: [3, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsReparacion',
      position: [0, 6],
      size: [1, 2],
      indicator: 'REPARACION_NPS',
      criticalMomentId: 3,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snReparacion',
      position: [1, 6],
      size: [1, 2],
      indicator: 'REPARACION_SATISFACCION',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosReparacion',
      position: [2, 6],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasReparacion',
      position: [3, 6],
      size: [1, 2],
      mapper: answeredMapper,
      url: 'survey-responses/values',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyReparacion',
      position: [0, 8],
      size: [4, 2],
      indicator: 'REPARACION_CUSTOMER_JOURNEY',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasReparacion',
      position: [0, 10],
      size: [2, 2],
      indicator: 'REPARACION_ROTATIVAS',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasReparacion',
      position: [2, 10],
      size: [2, 2],
      indicator: 'REPARACION_ROTATIVAS',
      criticalMomentId: 3
    }),
    //3
    {
      title: 'Estaciones',
      type: 'label',
      position: [0, 12],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de oficinas',
      position: [0, 13],
      size: [2, 2],
      indicator: 'REPARACION_SATISFACCION',
      criticalMomentId: 3,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsReparacion',
      position: [2, 13],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'REPARACION_SATISFACCION',
      criticalMomentId: 3,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsReparacion',
      position: [3, 13],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#db7476'
      },
      indicator: 'REPARACION_SATISFACCION',
      criticalMomentId: 3,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsReparacion',
      position: [0, 15],
      size: [1, 2],
      indicator: 'sentiment',
      criticalMomentId: 3,
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsReparacion',
      position: [1, 15],
      size: [1, 2],
      indicator: 'sentiment',
      criticalMomentId: 3,
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [2, 15],
      size: [2, 2],
      indicator: 'WORD_COUNT_WITH_LINKS',
      criticalMomentId: 3,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 17],
      size: [4, 4],
      criticalMomentId: 3,
    }),
  ],
} as DasboardConfig
