
import logoProfuturo from '@assets/logos/profuturo-logo.png'
import logoProfuturoSmall from '@assets/logos/profuturo-logo-small.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments, getProfuturoFilterCriticalMomentDefault, getProfuturoFilterCriticalMoments } from '../dashboard/dashHelper'
import { profuturoStaticDashboards } from '../dashboard/profuturo/staticDashboards'
import deafultHelpDefinition from '../help/defaultHelp'
import { omoikaneProfuturo } from '../omoikane/omoikaneProfuturo'



export const profuturoConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://profuturo-backend-voc.limetropy.com/api/',
    lcmBackendUrl: 'https://profuturo-backend-core.limetropy.com/api/ui/',
    caseBackendUrl: 'https://profuturo-backend-case.limetropy.com/api/ui/',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: '/voc',
    logoSmall: logoProfuturoSmall,
    logo: logoProfuturo,
    filtersFromDateSubstract: 1,
    filtersToDateSubstract: 0,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.CRITICAL_MOMENTS, FILTER_TYPES.DYNAMIC],
    supportsInternationalization: false,
    msExcelReportExt: 'xlsx',
    decimals: 0,
    menuDashboards: [DASHBOARDS.DEFAULT],
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    getFilterCriticalMoments: getProfuturoFilterCriticalMoments,
    getFilterCriticalMomentDefault: getProfuturoFilterCriticalMomentDefault,
    geosRootId: 1,
    logicalsRootId: 0,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    actionPlans: {
        geoLeafLevel: '',
        useWorstTags: true
    },
    omoikane: omoikaneProfuturo,
    useBackDashboards: true,
    staticDashboards: profuturoStaticDashboards,
    metadata: [
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS,
        //METADADATA_LOAD.WORKFLOWS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.profuturo,
        default: DASHBOARDS_CONFIGS.profuturo[DASHBOARDS.DEFAULT]
    },
    useDashboardDynamicPosition: true,
    surveyResponses: {
        url: 'survey-responses',
        type: 'post',
        tableColumns: undefined,
        tabs: [SurveyResponsesTab.ANSWERED],
        indicators: {
            nps: 'NPS_GENERAL'
        },
    },
    alerts: {
        useAlertWorkflows: true
    },
    notificationsConfig: {
        enabled: true
    },
    help: deafultHelpDefinition
}
