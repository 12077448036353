import { filterGeos } from '@utils/GeoUtils'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsBenavides'
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'

export default {
  title: 'General Dashboard',
  description: 'Dashboard General',
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'Satisfacción General por localización',
      key: 'venta-satisfaccion-zona',
      indicator: 'banregio_pregunta_4',
      position: [0, 0],
      size: [4, 4],
      extraConfigs: {
            backgroundColor: 'transparent',
            theme: 'dark',
            }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'Satisfacción General',
      indicator: 'banregio_pregunta_4', 
      position: [0, 4],
      size: [1, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'semicircleGeneric',
      title: 'NPS',
      key: 'nps-gauge',
      position: [1, 4],
      size: [1, 2],
      indicator: 'banregio_pregunta_5',
      historic: {
        type: 'line',
        formula: 'nps-10'
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de sentimientos',
      position: [0, 6],
      size: [4, 4],
      indicator: 'SENTIMENT',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [2, 4],
      size: [2, 2],
      indicator: 'WORD_COUNT',
    }),
   
  ]
}
