import { isArray } from "lodash"

export const filterChannel = (
  cms: Channel[],
  selected: number[]
) => {
  const cmsArray: any = cms.map((cm) => {
    return selected.includes(cm.id)
      ? doGetLeaves(cm)
      : filterChannel(cm.children, selected)
  })
  return [].concat(...cmsArray)
}

export const getLeaves = (cms: Channel | Channel[]): Channel[] => {
  if (isArray(cms)) {
    const arrayLeaves: any = cms.map((child) => doGetLeaves(child))
    return [].concat(...arrayLeaves)
  }

  const leaves: any =
    cms.children === undefined || cms.children.length === 0
      ? [cms.id]
      : cms.children.map((child) => doGetLeaves(child))
  return [].concat(...leaves)
}

export const getChildrens = (cms: Channel | Channel[]): Channel[] => {
  if (cms === undefined || cms === null) {
    return []
  }

  if (isArray(cms)) {
    const arrayChildren: any = cms.map((child) => getChildrens(child))
    return [].concat(...arrayChildren)
  }

  const children: any =
    cms.children === undefined || cms.children.length === 0
      ? [cms]
      : cms.children.map((child) => getChildrens(child))
  return [].concat(...children)
}

const doGetLeaves = (cm: Channel): Channel[] => {
  const leaves: any =
    cm.children === undefined || cm.children.length === 0
      ? [cm.id]
      : cm.children.map((child) => doGetLeaves(child))
  return [].concat(...leaves)
}

export const findChannel = (
  cms: Channel[],
  selected: number
): Channel | undefined => {
  if (cms === undefined || cms.length === 0) {
    return undefined
  }
  const criticalMoment: any = cms
    .map((cm) => {
      return selected === cm.id ? cm : findChannel(cm.children, selected)
    })
    .filter((item) => item?.id !== undefined)[0]

  return criticalMoment
}

export const findChannelByName = (
  cms: Channel[],
  name: string
): Channel | undefined => {
  if (cms === undefined || cms.length === 0) {
    return undefined
  }
  const criticalMoment: any = cms
    .map((cm) => {
      return name === cm.name ? cm : findChannelByName(cm.children, name)
    })
    .filter((item) => item?.id !== undefined)[0]

  return criticalMoment
}

export const getChannelsValues = (filtersData: Record<string, any>) => {
  if (
    filtersData.channels &&
    filtersData.channels[0] &&
    filtersData.channels[0].id == 0 &&
    filtersData.channels[0].children !== undefined &&
    filtersData.channels[0].children.length === 0
  ) {
    return undefined
  }

  return filtersData.channels &&
    filtersData.channels[0] &&
    filtersData.channels[0].id == 0
    ? filtersData.channels[0].children
    : filtersData.channels
}



export const selectedChannels = (chs: Channel[], selected: number[]): Channel[] => {
  //console.log('selected channels')
  //console.log(chs)
  //console.log(selected)
  if (chs === undefined || selected === undefined || selected.length === 0) {
    return []
  }
  const cmsArray: any = chs.map((ch) => {
    return selected.includes(ch.id)
      ? ch
      : selectedChannels(ch.children, selected)
  })
  return [].concat(...cmsArray)
}

