import { getCommonWidget } from "../../dashWidgetHelper";
import {
    PRODUTURO_PIE_COLORS, commonWidgets,
    getProfuturoFacilidad, getProfuturoNpsAcumulado,
    getProfuturoNpsPorMes, getProfuturoSatisfaccion,
    getProfuturoNpsConsar, PRODUTURO_SCALE_5_COLORS
} from "../commonWidgetsProfuturo";

export default {
    title: 'Acompañamiento Dashboard',
    description: 'Acompañamiento Dashboard',
    backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI) => [
        {
            title: 'Principales Indicadores',
            position: [0, 0],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getProfuturoNpsAcumulado({
            position: [0, 1],
            size: [1, 2],
            indicator: 'portal_privado_pregunta_19'
        }),
        getProfuturoNpsPorMes({
            position: [1, 1],
            size: [1, 2],
            indicator: 'portal_privado_pregunta_19',
            helpText: 'A partir de tu experiencia con el portal web Profuturo, en una escala de 0 a 10, ¿qué tan probable es que recomiendes a Profuturo con un amigo o familiar?'
        }),
        getProfuturoNpsConsar({
            title: 'NPS CONSAR',
            position: [2, 1],
            size: [1, 2],
            indicator: 'portal_privado_pregunta_1',
            helpText: '¿Qué tan probable es que recomiendes el servicio de portal web Profuturo con amigos y/o familiares?'
        }),
        getProfuturoSatisfaccion({
            position: [3, 1],
            size: [1, 2],
            indicator: 'portal_privado_pregunta_21',
            helpText: 'A partir de tu interacción con el portal web Profuturo ¿Qué tan satisfecho estás con las funciones y características disponibles?',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            }
        }),
        getProfuturoFacilidad({
            position: [0, 3],
            size: [1, 2],
            indicator: 'portal_privado_pregunta_2',
            helpText: 'Pensando en la última consulta o trámite que hiciste a través del portal web Profuturo, ¿qué tan fácil fue realizarlo?',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
        }),


        {
            title: 'Quiebres y Motivadores en la satisfacción',
            position: [0, 5],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Funciones que esperabas encontrar y no están disponibles',
            position: [0, 6],
            size: [2, 2],
            indicator: 'portal_privado_pregunta_22',
            helpText: '¿Qué funciones o características esperabas encontrar en el portal y no están disponibles?'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Funciones que más valoraste',
            position: [2, 6],
            size: [2, 2],
            indicator: 'portal_privado_pregunta_23',
            helpText: '¿Qué funciones o características valoraste más al utilizar el portal?'
        }),

        //
        {
            title: 'Quiebres y Motivadores en la facilidad',
            position: [0, 8],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Facilidad: clientes insatisfechos',
            position: [0, 9],
            size: [2, 2],
            indicator: 'portal_privado_pregunta_3',
            helpText: '¿Por qué resultó de esa manera? ',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 6,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Facilidad: clientes neutros',
            position: [2, 9],
            size: [2, 2],
            indicator: 'portal_privado_pregunta_5',
            helpText: '¿Qué podríamos hacer para mejorar la facilidad?',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 6,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Facilidad: clientes satisfechos',
            position: [0, 11],
            size: [2, 2],
            indicator: 'portal_privado_pregunta_7',
            helpText: '¿Por qué resultó fácil? ',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 6,
                center: ['27%', '55%'],
            }
        }),


        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Último trámite consultado',
            position: [2, 11],
            size: [2, 2],
            indicator: 'portal_privado_pregunta_9',
            helpText: '¿Cuál fue el último trámite o consulta que realizaste a través del portal web Profuturo? ',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 6,
                center: ['27%', '55%'],
            }
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMediumSiNo',
            title: 'Resolución del Trámite',
            position: [0, 13],
            size: [2, 2],
            helpText: '¿Pudiste resolver tu trámite o consulta?',
            indicator: 'portal_privado_pregunta_11',
            extraConfigs: {
                itemGap: 10,
                center: ['27%', '55%'],
            }
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Efectividad: Clientes insatisfechos',
            position: [2, 13],
            size: [2, 2],
            helpText: '¿Por qué no pudiste resolver tu trámite o consulta? ',
            indicator: 'portal_privado_pregunta_12',
            extraConfigs: {
                itemGap: 10,
                colors: PRODUTURO_PIE_COLORS,
                center: ['27%', '55%'],
            }
        }),


        {
            title: 'Atributos en la experiencia de Grado de SATISFACCIÓN',
            position: [0, 15],
            size: [4, 1],
            type: 'label',
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La rapidez y facilidad para ingresar al portal web',
            position: [0, 16],
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'portal_privado_pregunta_14',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La facilidad para encontrar la información / trámite que necesitaba',
            position: [0, 17],
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'portal_privado_pregunta_15',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'El modo en que pude resolver mis consultas / trámites',
            position: [0, 18],
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'portal_privado_pregunta_16',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'Lo simple y sencillo que resultó navegar en el portal web',
            position: [0, 19],
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'portal_privado_pregunta_17',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La claridad de la información que me brindó el portal web',
            position: [0, 20],
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'portal_privado_pregunta_18',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'Análisis de Sentimientos',
            position: [0, 21],
            size: [4, 4],
            indicator: 'SENTIMENT'
        }),

    ]
} as DasboardConfig
