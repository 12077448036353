import { getCommonWidget } from "../../dashWidgetHelper";
import { commonWidgets, getProfuturoFacilidad, getProfuturoNpsPorMes, getProfuturoSatisfaccion, 
    PRODUTURO_SCALE_3_COLORS, PRODUTURO_PIE_COLORS, PRODUTURO_PIE_SINO_COLORS, getProfuturoNpsAcumuladoFourMonth, getProfuturoCustomerJourney } from "../commonWidgetsProfuturo";

const extrasFechas: { [key: string]: any } = {
    // Pisamos las fechas del filtro general ya que se usan los filtros dinámicos
    dateFrom: '2018-01-01',
    dateTo: '2120-12-31'
};

export default {
	title: 'Acompañamiento Dashboard',
	description: 'Acompañamiento Dashboard',
	backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
	widgets: (filters: FiltersUI) => [
		{
			title: 'Principales Indicadores',
			position: [0, 0],
			size: [4, 1],
			type: 'label',
			config: { color: '#B7AADC' },
		},
		getProfuturoNpsAcumuladoFourMonth({
			position: [0, 1],
			size: [1, 2],
			indicator: 'est_cuenta_pregunta_14',
            extras: { periods: 6,
                     ...extrasFechas
                 },
            
		}),
		getProfuturoNpsPorMes({
			position: [1, 1],
			size: [1, 2],
			indicator: 'est_cuenta_pregunta_14',
            extras: extrasFechas,
            historicGrouped: 'responseValue',
            historicGroupByResponseValueId: 173003100,
            historicLabel: 'group',
            historicExtras: extrasFechas
		}),
        
     	getProfuturoSatisfaccion({
			position: [2, 1],
			size: [1, 2],
			indicator: 'est_cuenta_pregunta_2',
			helpText: '¿Qué tan satisfecho te encuentras con tu Estado de Cuenta?',
            extras: extrasFechas,
            historicGrouped: 'responseValue',
            historicGroupByResponseValueId: 173003100,
            historicLabel: 'group',
            historicExtras: extrasFechas,
		}),
        
		getProfuturoFacilidad({
			position: [3, 1],
			size: [1, 2],
			indicator: 'est_cuenta_pregunta_7',
			helpText: '¿Qué tan fácil te resultó descargar tu Estado de Cuenta desde tu mail?',
            extras: extrasFechas,
            historicGrouped: 'responseValue',
            historicGroupByResponseValueId: 173003100,
            historicLabel: 'group',
            historicExtras: extrasFechas,
		}),
        
      	{
			title: 'Quiebres y Motivadores en la satisfacción',
			position: [0, 3],
			size: [4, 1],
			type: 'label',
			config: { color: '#B7AADC' },
		},
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'pieGenericLarge',
			title: 'Satisfacción: clientes insatisfechos',
			position: [0, 4],
			size: [2, 2],
			helpText: '¿Por qué motivo calificaste  de esa manera?',
			indicator: 'est_cuenta_pregunta_22',
			extraConfigs: {
				colors: PRODUTURO_PIE_COLORS,
			},
            extras: extrasFechas,
		}),
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'pieGenericLarge',
			title: 'Satisfacción: clientes neutros',
			position: [2, 4],
			size: [2, 2],
			helpText: 'Podríamos hacerlo mejor, ¿Nos dirías cómo?',
			indicator: 'est_cuenta_pregunta_20',
			extraConfigs: {
				colors: PRODUTURO_PIE_COLORS,
			},
            extras: extrasFechas,
		}),

		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'pieGenericLarge',
			title: 'Satisfacción: Clientes satisfechos',
			position: [0, 6],
			size: [2, 2],
			helpText: '¡Qué bueno que lo hicimos bien! ¿Por qué motivo calificaste de esa manera?',
			indicator: 'est_cuenta_pregunta_21',
			extraConfigs: {
				colors: PRODUTURO_PIE_COLORS,
			},
            extras: extrasFechas,
		}),

		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'pieGenericLarge',
			title: 'Facilidad: Clientes insatisfechos',
			position: [2, 6],
			size: [2, 2],
			helpText: '¿Por qué no resultó fácil?',
			indicator: 'est_cuenta_pregunta_34',
			extraConfigs: {
				colors: PRODUTURO_PIE_COLORS,
			},
            extras: extrasFechas,
		}),
		{
			title: 'Otras graficas',
			position: [0, 8],
			size: [4, 1],
			type: 'label'
		},
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'pieGenericMedium',
			title: 'Medio de Contacto',
			position: [0, 9],
			size: [2, 2],
			helpText: '¿Por cuál medio preferirías recibir tu Estado de Cuenta?',
			indicator: 'est_cuenta_pregunta_23',
			extraConfigs: {
				colors: PRODUTURO_PIE_COLORS,
			},
            extras: extrasFechas,
		}),
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'pieGenericMedium',
			title: 'Efectividad',
			position: [2, 9],
			size: [2, 2],
			helpText: '¿Has visitado la sección en profutro.mx sobre cómo leer el Estado de Cuenta?',
			indicator: 'est_cuenta_pregunta_27',
			extraConfigs: {
				colors: PRODUTURO_PIE_SINO_COLORS,
			},
            extras: extrasFechas,
		}),
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'wordCount',
			title: 'Visita a profuturo.mx',
			position: [0, 11],
			size: [4, 2],
			helpText: '¿Qué te pareció?',
			indicator: 'est_cuenta_pregunta_35',
            extras: extrasFechas,
		}),
        getProfuturoCustomerJourney({
             id: 'cj-estadoDeCuenta',
             position: [0, 13],
             size: [3, 2],
             indicators: [  { name: 'est_cuenta_pregunta_2', formula: 'count-number' , extrasFechas: extrasFechas }, //csat
                            { name: 'est_cuenta_pregunta_7', formula: 'count-number', extrasFechas: extrasFechas  }, //facilidad
                            { name: 'est_cuenta_pregunta_14', formula: 'nps-10', extrasFechas: extrasFechas  }, //nps
             ],
             title: 'Customer Journey: Top Two Box',
             
         }),
		{
			title: 'Grado de Satisfacion',
			position: [0, 15],
			size: [4, 1],
			type: 'label'
		},
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'stackedBar',
			title: 'La recepción del Estado de Cuenta cada 4 meses',
			position: [0, 16],
			size: [4, 1],
			helpText: '¿Qué tan satisfecho/a te encuentras con los siguientes aspectos respecto a tu Estado de Cuenta que recibiste?',
			indicator: 'est_cuenta_pregunta_30',
            extras: extrasFechas,
		}),
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'stackedBar',
			title: 'La claridad de la información en el Estado de Cuenta',
			position: [0, 17],
			size: [4, 1],
			helpText: '¿Qué tan satisfecho/a te encuentras con los siguientes aspectos respecto a tu Estado de Cuenta que recibiste?',
			indicator: 'est_cuenta_pregunta_16',
            extras: extrasFechas,
		}),
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'stackedBar',
			title: 'Las instrucciones para descargar el Estado de Cuenta son claras',
			position: [0, 18],
			size: [4, 1],
			helpText: '¿Qué tan satisfecho/a te encuentras con los siguientes aspectos respecto a tu Estado de Cuenta que recibiste?',
			indicator: 'est_cuenta_pregunta_24',
            extras: extrasFechas,
		}),
		{
			title: 'Grado de Acuerdo',
			position: [0, 19],
			size: [4, 1],
			type: 'label'
		},
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'stackedBar',
			title: 'El Estado de Cuenta me muestra claramente lo que ahorré, lo que creció mi ahorro y lo que me cobraron',
			position: [0, 20],
			size: [4, 1],
			helpText: '¿Estás de acuerdo con los siguientes enunciados?',
			indicator: 'est_cuenta_pregunta_9',
			extraConfigs: {
				colors: PRODUTURO_SCALE_3_COLORS
			},
            extras: extrasFechas,
		}),
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'stackedBar',
			title: 'El Estado de Cuenta me ayuda a saber si voy bien o mal para mi retiro',
			position: [0, 21],
			size: [4, 1],
			helpText: '¿Estás de acuerdo con los siguientes enunciados?',
			indicator: 'est_cuenta_pregunta_10',
			extraConfigs: {
				colors: PRODUTURO_SCALE_3_COLORS
			},
            extras: extrasFechas,
		}),
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'stackedBar',
			title: 'El Estado de Cuenta me presenta alternativas de lo que debo hacer para mejorar mi futuro',
			position: [0, 22],
			size: [4, 1],
			helpText: '¿Estás de acuerdo con los siguientes enunciados?',
			indicator: 'est_cuenta_pregunta_11',
			extraConfigs: {
				colors: PRODUTURO_SCALE_3_COLORS
			},
            extras: extrasFechas,
		}),
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'stackedBar',
			title: 'El Estado de Cuenta me deja tranquilo de que mi dinero está bien invertido y seguro',
			position: [0, 23],
			size: [4, 1],
			helpText: '¿Estás de acuerdo con los siguientes enunciados?',
			indicator: 'est_cuenta_pregunta_12',
			extraConfigs: {
				colors: PRODUTURO_SCALE_3_COLORS
			},
            extras: extrasFechas,
		}),
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'stackedBar',
			title: 'Puedo consultar mi Estado de Cuenta en el momento que quiera en los diferentes canales que ofrece Profuturo',
			position: [0, 24],
			size: [4, 1],
			helpText: '¿Estás de acuerdo con los siguientes enunciados?',
			indicator: 'est_cuenta_pregunta_13',
			extraConfigs: {
				colors: PRODUTURO_SCALE_3_COLORS
			},
            extras: extrasFechas,
		}),
		getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'stackedBar',
			title: 'Utilizo mi Estado de Cuenta para tomar  decisiones y planear sobre mi futuro',
			position: [0, 25],
			size: [4, 1],
			helpText: '¿Estás de acuerdo con los siguientes enunciados?',
			indicator: 'est_cuenta_pregunta_25',
			extraConfigs: {
				colors: PRODUTURO_SCALE_3_COLORS
			},
            extras: extrasFechas,
		}),

    	getCommonWidget({
			commonWidgets: commonWidgets,
			name: 'textAnalysis',
			title: 'Análisis de Sentimientos',
			position: [0, 26],
			size: [4, 4],
			indicator: 'SENTIMENT',
            extras: extrasFechas,
		}),
	]
} as DasboardConfig
