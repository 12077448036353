import { ClientConfigInfo } from './configs/configCommons'
import { oxxoConfigs } from './configs/oxxo'
import { mitsubishiConfigs } from './configs/mitsubishi'
import { haConfigs } from './configs/ha'
import { gnpConfigs } from './configs/gnp'
import { suzukiConfigs } from './configs/suzuki'
import { caffenioConfigs } from './configs/caffenio'
import { bydConfigs } from './configs/byd'
import { localConfigs } from './configs/local'
import { iamsaConfigs } from './configs/iamsa'
import { profuturoConfigs } from './configs/profuturo'
import { jacConfigs } from './configs/jac'
import { benavidesConfigs } from './configs/benavides'
import { bupaConfigs } from './configs/bupa'

interface ClientConfigList {
    [client: string]: ClientConfigInfo
}

const CLIENTS_CONFIGS: ClientConfigList = {
    benavides: benavidesConfigs,
    banregio: benavidesConfigs,
    bupa: bupaConfigs,
    byd: bydConfigs,
    caffenio: caffenioConfigs,
    gnp: gnpConfigs,
    ha: haConfigs,
    iamsa: iamsaConfigs,
    jac: jacConfigs,
    local: localConfigs,
    mitsubishi: mitsubishiConfigs,
    oxxo: oxxoConfigs,
    profuturo: profuturoConfigs,
    suzuki: suzukiConfigs
}

export const getClientConfigs = (clientName: string): ClientConfigInfo => {
    //const clientName = getClientName()
    return CLIENTS_CONFIGS[clientName]
}

