
import logoBanregio from '@assets/logos/banregio-logo.png'
import logoBanregioSmall from '@assets/logos/banregio-logo-small.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import { omoikaneBenavides } from '../omoikane/omoikaneBenavides'
import deafultHelpDefinition from '../help/defaultHelp'


export const benavidesConfigs: ClientConfigInfo = {
    // Epsilon back
    vocBackendUrl: 'https://benavides-backend-voc.limetropy.com/api/',
    lcmBackendUrl: 'https://benavides-backend-core.limetropy.com/backend/core/api/ui/',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: '/voc',
    logoSmall: logoBanregioSmall,
    logo: logoBanregio,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 0,
    decimals: undefined,
    supportsInternationalization: false,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    menuDashboards: [DASHBOARDS.DEFAULT],
    msExcelReportExt: 'xls',
    geosRootId: 0,
    logicalsRootId: 0,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    actionPlans: {
        geoLeafLevel: 'LOCAL',
        useWorstTags: true
    },
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    omoikane: omoikaneBenavides,
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.benavides,
        default: DASHBOARDS_CONFIGS.benavides[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponses: {
        url: 'survey-responses',
        type: 'post',
        tableColumns: undefined,
        tabs: [SurveyResponsesTab.ANSWERED/*, SurveyResponsesTab.NOT_ANSWERED*/],
        indicators: {
            nps: 'NPS_GENERAL'
        }
    },
    alerts: {},
    help: deafultHelpDefinition
}
