import { getCommonWidget } from "../dashWidgetHelper"
import { bupaStakedBarDetailedByMonthMapper, commonWidgets } from "./commonWidgetsBupa"



const getBupaWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => [

  getCommonWidget({
    commonWidgets: commonWidgets,
    //name: 'barDetailedByMonthAndGeo',
    name: 'barByMonth',
    title: 'NPS General por mes',
    size: [4, 3],
    // @ts-ignore
    mapper: bupaStakedBarDetailedByMonthMapper,
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
      titleAlign: 'center'
    },
    indicator: 'NPS',
    //render: (filters: FiltersUI) => renderForCriticalMoment(filters, [29]),
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'semicircleGeneric',
    title: 'NPS',
    helpText: 'NPS',
    key: 'nps-gauge',
    size: [2, 2],
    indicator: 'NPS',
    historic: {
      type: 'line',
      formula: 'nps-10',
      theme: 'dark'
    },
    mapper: (data: any, extra: any): any => {
      return data
    },
    extraConfigs: {
      backgroundColor: '#5F2B87',
      fontColor: 'white',
      showCount: true,
      tooltipFormatter: (props: any) => {
        return `${props.data.name}: ${props.data.value}% (${props.data.count})`
      }
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'semicircleGeneric',
    title: 'Satisfacción',
    key: 'nps-gauge',
    size: [2, 2],
    indicator: 'SAT',
    historic: {
      type: 'line',
      formula: 'nps-10',
      theme: 'dark'
    },
    extraConfigs: {
      backgroundColor: '#5F2B87',
      fontColor: 'white',
      showCount: true,
      tooltipFormatter: (props: any) => {
        return `${props.data.name}: ${props.data.value}% (${props.data.count})`
      }
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'surveyCountTrendByMonth',
    size: [2, 2],
    indicator: 'survey-count'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'surveyCountTrendByWeek',
    size: [2, 2],
    indicator: 'survey-count'
  }),
  /*getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'alertsTrendByMonth',
      subTitle: 'widgets.alertsCountMonth',
    position: [2, 3],
    size: [1, 2],
    indicator: 'alert-first-response-time'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'alertsTrendByWeek',
      subTitle: 'widgets.alertsCountWeek',
    position: [3, 3],
    size: [1, 2],
    indicator: 'alert-first-response-time'
  }),*/
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    size: [4, 4],
    indicator: 'SENTIMENT'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'diagramaIshikawa',
    title: 'widgets.ishikawa',
    size: [4, 2],
    indicator: 'SENTIMENT'
  }),
  /*getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCountWithLinks',
    title: 'Nube de Palabras / Frecuencia en comentarios',
    position: [0, 11],
    size: [4, 2],
    extraConfigs: {
      tagsLimit: 50
    }
  }),*/
  
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'mexMapByGeos',
    title: 'NPS por Estado',
    size: [4, 3],
    indicator: 'NPS',
    extraConfigs: {
      bestLocationValueFrom: 90,
      worstLocationValueTo: 90
    }
  }),
]

const bupaTestWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => [
  
  /*getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'mapByGeos',
    title: 'Mapa de estaciones',
    position: [0, 0],
    size: [4, 2],
    indicator: 'NPS',
    extraConfigs: {
      bestLocationValueFrom: 90,
      worstLocationValueTo: 90
    }
  }),*/
  
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'mexMapByGeos',
    title: 'Mapa de estaciones',
    position: [0, 2],
    size: [3, 2],
    indicator: 'NPS',
    extraConfigs: {
      bestLocationValueFrom: 90,
      worstLocationValueTo: 90
    }
  }),
]

export default {
  title: 'Bupa Dashboard',
  description: 'Dashboard de Bupa',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI, filtersData: Record<string, any>) => {
    return getBupaWidgets(filters, filtersData)
    //return bupaTestWidgets(filters, filtersData)
  },
} as DasboardConfig
