import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_PIE_COLORS, commonWidgets, getProfuturoAtencion, getProfuturoNpsAcumulado,
     getProfuturoNpsPorMes, getProfuturoSatisfaccion, getProfuturoCustomerJourney } from "../commonWidgetsProfuturo";

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    {
      title: 'Principales indicadores',
      position: [0, 0],
      size: [4, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getProfuturoNpsAcumulado({
      position: [0, 1],
      size: [1, 2],
      indicator: 'cuent_institucionales_pregunta_18'
    }),
    getProfuturoNpsPorMes({
      position: [1, 1],
      size: [1, 2],
      indicator: 'cuent_institucionales_pregunta_18',
    }),
    getProfuturoSatisfaccion({
      position: [2, 1],
      size: [1, 2],
      indicator: 'cuent_institucionales_pregunta_2',
      helpText: '¿Qué tan satisfecho estás con el contacto realizado por parte de Profuturo?'
    }),
    getProfuturoAtencion({
      position: [3, 1],
      size: [1, 2],
      indicator: 'cuent_institucionales_pregunta_6',
      helpText: '¿Cómo calificarías la atención recibida?'
    }),
    {
      title: 'Motivadores y quiebres',
      position: [0, 3],
      size: [4, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Satisfacción: clientes insatisfechos',
      position: [0, 4],
      size: [4, 2],
      indicator: 'cuent_institucionales_pregunta_5',
      helpText: '¿En qué podríamos mejorar?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Satisfacción: clientes neutros',
      position: [0, 6],
      size: [2, 2],
      indicator: 'cuent_institucionales_pregunta_4',
      helpText: '¿En qué podríamos mejorar?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Satisfacción: clientes satisfechos',
      position: [2, 6],
      size: [2, 2],
      indicator: 'cuent_institucionales_pregunta_3',
      helpText: '¿Qué fue lo que más valoraste?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Atención: clientes neutros e insatisfechos',
      position: [0, 8],
      size: [2, 2],
      indicator: 'cuent_institucionales_pregunta_8',
      helpText: '¿En qué podríamos mejorar?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Atención: clientes satisfechos',
      position: [2, 8],
      size: [2, 2],
      indicator: 'cuent_institucionales_pregunta_7',
      helpText: '¿Qué fue lo que más valoraste de la interacción?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMediumSiNo',
      title: 'Efectividad',
      position: [0, 10],
      size: [1, 2],
      helpText: '. ¿Se resolvió tu necesidad a partir del contacto que tuviste con Profuturo?',
      indicator: 'cuent_institucionales_pregunta_14'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Motivos de la consulta no resuelta',
      position: [1, 10],
      size: [3, 2],
      helpText: ' ¿Por qué no pudiste se resolvió?',
      indicator: 'cuent_institucionales_pregunta_15'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMediumSiNo',
      title: 'Contrataría su pensión con Profuturo',
      position: [0, 12],
      size: [2, 2],
      helpText: '. Si la atención que recibes por parte de Profuturo se mantiene como hasta ahora, ¿considerarías contratar tu pensión con nosotros?',
      indicator: 'cuent_institucionales_pregunta_16'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Motivos de no elección de pensión con Profuturo',
      position: [2, 12],
      size: [2, 2],
      helpText: '¿Por qué?',
      indicator: 'cuent_institucionales_pregunta_17'
    }),
    getProfuturoCustomerJourney({
           id: 'cj-cuentasInstitucionales',
           position: [0, 14],
           size: [3, 2],
           indicators: [ { name: 'cuent_institucionales_pregunta_2', formula: 'count-number' }, //csat
                         { name: 'cuent_institucionales_pregunta_6', formula: 'count-number' }, // atencion
                         { name: 'cuent_institucionales_pregunta_18', formula: 'nps-10' }, //nps
           ],
           title: 'Customer Journey: Top Two Box',
          
       }),
    
    {
      title: 'Atributos de experiencia',
      position: [0, 16],
      size: [4, 1],
      type: 'label',
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'El conocimiento y la claridad en la información acerca de mi cuenta individual',
      position: [0, 17],
      size: [4, 1],
      helpText: '¿Qué tan satisfecho te encuentras con los siguientes aspectos sobre tu último contacto con Profuturo?',
      indicator: 'cuent_institucionales_pregunta_10'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'El compromiso e interés para responder a mis inquietudes',
      position: [0, 18],
      size: [4, 1],
      helpText: '¿Qué tan satisfecho te encuentras con los siguientes aspectos sobre tu último contacto con Profuturo?',
      indicator: 'cuent_institucionales_pregunta_11'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Resolvieron mis necesidades de manera eficiente',
      position: [0, 19],
      size: [4, 1],
      helpText: '¿Qué tan satisfecho te encuentras con los siguientes aspectos sobre tu último contacto con Profuturo?',
      indicator: 'cuent_institucionales_pregunta_12'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'Recibí asesoría personalizada que me permite tomar decisiones para mejorar mi futuro',
      position: [0, 20],
      size: [4, 1],
      helpText: '¿Qué tan satisfecho te encuentras con los siguientes aspectos sobre tu último contacto con Profuturo?',
      indicator: 'cuent_institucionales_pregunta_13'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Comentarios',
      position: [0, 21],
      size: [4, 2],
      helpText: ' Por último, ¿tienes alguna sugerencia para mejorar tu experiencia con Profuturo?',
      indicator: 'cuent_institucionales_pregunta_19'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de Sentimientos',
      position: [0, 23],
      size: [4, 4],
      indicator: 'SENTIMENT'
    }),
  ]
} as DasboardConfig