import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from "./commonWidgetsCaffenio";

export default {
  title: 'Apertura Dashboard',
  description: 'Apertura Default',
  widgets: (filters: FiltersUI) => [

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: "Satisfacción General",
      position: [0, 0],
      size: [4, 4],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'apertura_pregunta_1',
      criticalMomentId: 10252
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Satisfacción General',
      position: [0, 4],
      size: [1, 2],
      indicator: 'apertura_pregunta_1',
      criticalMomentInclude: [10252],
      extraConfigs: {
              useCurrent: 0,
            }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Recomendación',
      position: [1, 4],
      size: [1, 2],
      indicator: 'apertura_pregunta_5',
      criticalMomentInclude: [10252],
      extraConfigs: {
              useCurrent: 0,
            }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Recompra',
      position: [2, 4],
      size: [2, 2],
      indicator: 'apertura_pregunta_4',
      criticalMomentInclude: [10252],
      extraConfigs: {
              useCurrent: 0,
            }
    }),
    /*getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: '% Registros válidos para envío de encuestas',
      position: [3, 4],
      size: [1, 2],
      criticalMomentId: 10252,
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: '% tasa de respuesta',
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [0, 5],
      size: [1, 2],
      criticalMomentId: 10252,
    }),*/
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Satisfacción: Clientes insatisfechos',
      position: [0, 6],
      size: [2, 2],
      indicator: 'apertura_pregunta_2',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa puntos de ventas',
      position: [2, 6],
      size: [2, 2],
      criticalMomentId: 10252,
      indicator: 'apertura_pregunta_1',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Conocimiento de la apertura',
      position: [0, 8],
      size: [2, 2],
      indicator: 'apertura_pregunta_6',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Medio por el cual conoce el nuevo establecimiento',
      position: [2, 8],
      size: [2, 2],
      indicator: 'apertura_pregunta_7',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Adjetivo: Nueva sucursal',
      position: [0, 10],
      size: [4, 2],
      indicator: 'apertura_pregunta_8',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Donde consumió anteriormente',
      position: [0, 12],
      size: [2, 2],
      indicator: 'apertura_pregunta_9',
      criticalMomentInclude: [10252],
    }),
    
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿En qué cafetería?',
      position: [2, 12],
      size: [2, 2],
      indicator: 'apertura_pregunta_10',
      criticalMomentInclude: [10252],
    }),
    
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'En casa u oficina ¿Qué tipo de café?.',
      position: [0, 14],
      size: [2, 2],
      indicator: 'apertura_pregunta_11',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Tienda de conveniencia',
      position: [2, 14],
      size: [2, 2],
      indicator: 'apertura_pregunta_12',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Hace cuanto consumes CAFFENIO?',
      position: [0, 16],
      size: [2, 2],
      indicator: 'apertura_pregunta_15',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Momento del día',
      position: [2, 16],
      size: [2, 2],
      indicator: 'apertura_pregunta_18',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Tiempo de traslado',
      position: [0, 18],
      size: [4, 2],
      indicator: 'apertura_pregunta_19',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Colonia dónde vienes',
      position: [0, 20],
      size: [2, 2],
      indicator: 'apertura_pregunta_38',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Colonia dónde vas',
      position: [2, 20],
      size: [2, 2],
      indicator: 'apertura_pregunta_51',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
         commonWidgets: commonWidgets,
         name: 'pieGenericMedium',
         title: 'De dónde vienes.',
         position: [0, 22],
         size: [2, 2],
         indicator: 'apertura_pregunta_61',
         criticalMomentInclude: [10252],
       }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Dónde te diriges',
      position: [2, 22],
      size: [2, 2],
      indicator: 'apertura_pregunta_20',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Facilidad al usar la pantalla',
      position: [0, 24],
      size: [2, 2],
      indicator: 'apertura_pregunta_25',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: 'Le atendieron rápidamente',
      position: [2, 24],
      size: [2, 2],
      indicator: 'apertura_pregunta_27',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Cuánto tiempo tomó la entrega de su pedido? ',
      position: [0, 26],
      size: [2, 2],
      indicator: 'apertura_pregunta_28',
      criticalMomentInclude: [10252],
        }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: 'Le atendieron con amabilidad',
      position: [2, 26],
      size: [2, 2],
      indicator: 'apertura_pregunta_29',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Encontró el producto que buscaba',
      position: [0, 28],
      size: [2, 2],
      indicator: 'apertura_pregunta_30',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Qué producto te gustaría que agregáramos a nuestro menú?',
      position: [2, 28],
      size: [2, 2],
      extraConfigs: {
        center: ['30%', '55%'],
        itemGap: 15
      },
      indicator: 'apertura_pregunta_42',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Describe a detalle el producto que te gustaría que agregaramos a nuestro menú',
      position: [0, 30],
      size: [2, 2],
      indicator: 'apertura_pregunta_39',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: 'Utilizaste servicio Drive',
      position: [2, 30],
      size: [1, 2],
      extraConfigs: {
        center: ['50%', '55%'],
        legendTop: 'top',
      },
      indicator: 'apertura_pregunta_24',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
         commonWidgets: commonWidgets,
         name: 'gaugeGeneric',
         title: 'Calificación del acceso al DRIVE THRU',
         position: [3, 30],
         size: [1, 2],
         indicator: 'apertura_pregunta_46',
         criticalMomentInclude: [10252],
         extraConfigs: {
                 useCurrent: 0,
               }
       }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGender',
      title: 'Género',
      position: [0, 32],
      size: [1, 2],
      extraConfigs: {
        center: ['50%', '55%'],
        legendTop: 'top',
      },
      indicator: 'apertura_pregunta_33',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Edad',
      position: [1, 32],
      size: [1, 2],
      indicator: 'apertura_pregunta_34',
      criticalMomentInclude: [10252]
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [2, 32],
      size: [2, 2],
      criticalMomentId: 10252,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Analisis de Sentimientos',
      position: [0, 34],
      size: [4, 4],
      indicator: "SENTIMENT",
      criticalMomentId: 10252
    }),

  ]
}
