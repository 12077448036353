import { create } from 'apisauce'
import config from '@config'
import RestApiBuilder from '@utils/RestApiBuilder'

// Import the entities apis
import AuthApi from './AuthApi'
import AccountApi from './AccountApi'
import ReportsApi from './ReportsApi'
import IndicatorsApi from './IndicatorsApi'
import AlertsApi from './AlertsApi'
import SurveyResponsesApi from './SurveyResponsesApi'
import ActionPlansApi from './ActionPlansApi'
import DynamicReportsApi from './DynamicReportsApi'
import SocialListenerApi from './SocialListenerApi'
import ClientAccountsApi from './ClientAccountsApi'
import AlertWorkflowsApi from './AlertWorkflowsApi'
import AlertWorkGroupsApi from './AlertWorkGroupsApi'

export const vocApi = create({
  baseURL: config.VOC_API_URL,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json; charset=utf-8',
    'access-control-allow-headers':
      'Content-Type, Accept, X-Requested-With, Authorization, X-Total-Count',
  },
})

export const lcmApi = create({
  baseURL: config.LCM_API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json; charset=utf-8',
    'access-control-allow-headers':
      'Content-Type, Accept, X-Requested-With, Authorization, X-Total-Count',
  },
})

export const caseApi = create({
  baseURL: config.CASE_API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json; charset=utf-8',
    'access-control-allow-headers':
      'Content-Type, Accept, X-Requested-With, Authorization, X-Total-Count',
  },
})

export default {
  auth: AuthApi(vocApi),
  account: AccountApi(vocApi),
  alerts: AlertsApi(vocApi),
  tenants: RestApiBuilder('tenants', vocApi),
  roles: RestApiBuilder<Role>('profiles', vocApi),
  //criticalMoments: CriticalMomentsApi<CriticalMoment>(apiInstance),
  criticalMoments: RestApiBuilder<CriticalMoment>(
    'critical-moments',
    vocApi
  ),
  logicalDistributions: RestApiBuilder<LogicalDistribution>(
    'logical-locations',
    vocApi
  ),
  geoDistributions: RestApiBuilder<GeoDistribution>(
    'geo-locations',
    vocApi
  ),
  channels: RestApiBuilder<Channel>(
    'channels',
    vocApi
  ),
  clusters: RestApiBuilder<Cluster>('clusters', vocApi),
  users: RestApiBuilder('users/tenant', vocApi),
  admins: RestApiBuilder('users/admin', vocApi),
  workflows: RestApiBuilder('workflows', lcmApi),
  surveys: RestApiBuilder('surveys', vocApi),
  surveysResponse: SurveyResponsesApi(vocApi, lcmApi),
  clientAccounts: ClientAccountsApi(vocApi, lcmApi),
  reports: ReportsApi<Report>(vocApi),
  dynamicReportsFields: DynamicReportsApi<DynamicReportField>(vocApi),
  indicators: IndicatorsApi<Indicator>(vocApi, lcmApi),
  actionPlans: ActionPlansApi<ActionPlan>(vocApi),
  customerJourney: RestApiBuilder<ActionPlan>('customer-journey', vocApi),
  dashboardBuilder: RestApiBuilder<Indicator>('dashboard', vocApi),
  socialListener: SocialListenerApi(vocApi),
  alertWorkflows: AlertWorkflowsApi(caseApi),
  alertWorkGroup: AlertWorkGroupsApi(caseApi),
  //socialListener: SocialListenerApi(vocApi),
}
