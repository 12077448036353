import { group } from "console";
import acompanamiento from "../dashboard/profuturo/staticDashboards/acompanamiento";
import ventaPrestamosSOFOM from "../dashboard/profuturo/staticDashboards/ventaPrestamosSOFOM";
import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

// TODO: mover estos metodos a commons cuando este probado se podria unificar con getCJIndicators de commonwiegets
const getIndicator = (config: { [key: string]: any } = {}) => {
    return {
        id: config.id ?? undefined,
        indicator: config.name,
        keyExtract: [],
        key: config.key ?? config.name,
        grouped: config.grouped ?? "question",
        extras: {
            omoikane: config?.omoikane ?? true,
            groupNameFormat: config?.groupNameFormat ?? "description",
            criticalMomentInclude: config.criticalMomentInclude ?? [],
            resultType: config.resultType ?? undefined,
            formula: config.formula ?? undefined,
        }
    };
};

const getCommentIndicator = (config: { [key: string]: any } = {}) => {
    const indicatorConfigs = getIndicator(config)
    return {
        ...indicatorConfigs,
        grouped: undefined,
    }
};

const getSentimentIndicator = (key: string, criticalMomentInclude: number[]) => {
    const config = {
        name: 'SENTIMENT',
        key: key,
        grouped: 'tag',
        criticalMomentInclude: criticalMomentInclude

    }
    return getIndicator(config);
}

const getCountIndicator = (key: string, criticalMomentInclude: number[]) => {
    const config = {
        name: 'SURVEY_COUNT',
        key: key,
        grouped: 'mlCluster',
        criticalMomentInclude: criticalMomentInclude
    }
    return getIndicator(config);
}

const getClusterIndicators = (csatIndicator: string, npsIndicator: string, criticalMomentInclude: number[]) => {
    return [
        getIndicator({ name: csatIndicator, id: 'csat', grouped: 'mlCluster', criticalMomentInclude: criticalMomentInclude }),
        getIndicator({ name: npsIndicator, id: 'nps', grouped: 'mlCluster', criticalMomentInclude: criticalMomentInclude }),
        getIndicator({ name: 'SENTIMENT', id: 'best-tags', grouped: 'mlCluster,tag', criticalMomentInclude: criticalMomentInclude, resultType: 'top' }),
        getIndicator({ name: 'SENTIMENT', id: 'worst-tags', grouped: 'mlCluster,tag', criticalMomentInclude: criticalMomentInclude, resultType: 'bottom' }),
        getCommentIndicator({ name: 'COMMENTS', id: 'last-comments', criticalMomentInclude: criticalMomentInclude }),
        getIndicator({ name: 'SURVEY_COUNT', id: 'alerts', grouped: 'mlCluster', criticalMomentInclude: criticalMomentInclude }),
        getIndicator({ name: 'ALERT_COUNTERS', id: 'alerts-counters', grouped: 'mlCluster,alertType', criticalMomentInclude: criticalMomentInclude })

    ]

}

const getCJIndicatorItems = (data: any, indicatorNames: string[]) => {
    const items: any[] = [];
    let pos = 1;

    indicatorNames.forEach((indicatorName) => {
        if (data && data[indicatorName]?.data) {

            const sortedValues = data[indicatorName]?.data
                .asMutable()
                .sort((c1: any, c2: any) => c1.groupId - c2.groupId)
                .map((item: any) => ({ value: item.value, name: indicatorName, group: item.group }));

            const item = sortedValues?.[0];
            //const item2 = sortedValues?.[1];  

            items.push({
                key: pos,
                number: (pos).toString(),
                title: item.group,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            });
            pos++;

        }
    });

    return items;
};
export const omoikaneProfuturo: OmoikaneConfig = {
    decimals: 2,
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP,
        OmoikaneTab.CLUSTERS
    ],
    monthdsCoverage: 2,
    indicators: [

        //autoservicio con asesor
        getIndicator({ name: 'autoserv_con_asesor_pregunta_3', formula: 'nps-5' }), // atencion
        getIndicator({ name: 'autoserv_con_asesor_pregunta_5', formula: 'nps-5' }), // facilidad
        getIndicator({ name: 'autoserv_con_asesor_pregunta_9', formula: 'nps-5' }), // csat
        getIndicator({ name: 'autoserv_con_asesor_pregunta_14', formula: 'nps-10' }), // nps

        //autoservicio sin asesor
        getIndicator({ name: 'autoserv_sin_asesor_pregunta_1', formula: 'nps-5' }), // csat
        getIndicator({ name: 'autoserv_sin_asesor_pregunta_21', formula: 'nps-10' }), // nps

        //inidicadores de sentimiento
        getSentimentIndicator('sentiment-autoserv', [11451]), //el indicador de sentiment de autoservicio es el mismo para asesor y sin asesor asi que va el mismo critical
        //contadores cluster
        getCountIndicator('survey-count-autoserv', [11451]),


        //App mobile
        getIndicator({ name: 'app_pregunta_4', formula: 'nps-5' }), //facilidad
        //getIndicator({name: 'app_pregunta_7', formula: 'nps-5'}), //resoluciion es un radio de 3 opciones 
        getIndicator({ name: 'app_pregunta_15', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-appmobile', [10352]),
        getCountIndicator('survey-count-appmobile', [10352]),

        //Alba chatbot
        getIndicator({ name: 'albapregunta_4', formula: 'nps-5' }), // facilidad
        getIndicator({ name: 'albapregunta_2', formula: 'nps-5' }), //csat
        getIndicator({ name: 'albapregunta_18', formula: 'nps-10' }), // nps
        getSentimentIndicator('sentiment-albachatbot', [10351]),
        getCountIndicator('survey-count-albachatbot', [10351]),

        //Call center
        getIndicator({ name: 'call_center_l_pregunta_2', formula: 'nps-5' }), //csat
        getIndicator({ name: 'call_center_l_pregunta_6', formula: 'nps-5' }), // facilidad
        getIndicator({ name: 'call_center_l_pregunta_17', formula: 'nps-5' }), //atencion
        getIndicator({ name: 'call_center_l_pregunta_35', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-callcenter', [10373]),
        getCountIndicator('survey-count-callcenter', [10373]),

        //Chat humano
        getIndicator({ name: 'chat_humano_l_pregunta_2', formula: 'nps-5' }), //csat
        getIndicator({ name: 'chat_humano_l_pregunta_3', formula: 'nps-5' }), // atencion
        getIndicator({ name: 'chat_humano_l_pregunta_15', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-chathumano', [10370]),
        getCountIndicator('survey-count-chathumano', [10370]),

        //Cuentas Institucionales
        getIndicator({ name: 'cuent_institucionales_pregunta_2', formula: 'nps-5' }), //csat
        getIndicator({ name: 'cuent_institucionales_pregunta_6', formula: 'nps-5' }), // atencion
        getIndicator({ name: 'cuent_institucionales_pregunta_18', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-cuentasinst', [10366]),
        getCountIndicator('survey-count-cuentasinst', [10366]),

        //custodia presencial
        getIndicator({ name: 'cust_presencial_V2_pregunta_2', formula: 'nps-5' }), //csat
        getIndicator({ name: 'cust_presencial_V2_pregunta_6', formula: 'nps-5' }), // atencion
        getIndicator({ name: 'cust_presencial_V2_pregunta_18', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-custpres', [10365]),
        getCountIndicator('survey-count-custpres', [10365]),

        //custodia telefonica
        getIndicator({ name: 'cust_telefonica_pregunta_2', formula: 'nps-5' }), //csat
        getIndicator({ name: 'cust_telefonica_pregunta_6', formula: 'nps-5' }), // atencion
        getIndicator({ name: 'cust_telefonica_pregunta_18', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-custtel', [10364]),
        getCountIndicator('survey-count-custtel', [10364]),

        //sucursales
        getIndicator({ name: 'sucursal_pregunta_2', formula: 'count-number' }), //csat
        getIndicator({ name: 'sucursal_pregunta_4', formula: 'count-number' }), //tiempo espera
        getIndicator({ name: 'sucursal_pregunta_9', formula: 'count-number' }), //atencion
        getIndicator({ name: 'sucursal_pregunta_22', formula: 'nps-10' }), //nps

        getIndicator({ name: 'sucursal_pregunta_12', formula: 'count-number' }),
        getIndicator({ name: 'sucursal_pregunta_13', formula: 'count-number' }),
        getIndicator({ name: 'sucursal_pregunta_14', formula: 'count-number' }),
        getIndicator({ name: 'sucursal_pregunta_15', formula: 'count-number' }),
        getIndicator({ name: 'sucursal_pregunta_16', formula: 'count-number' }),
        getIndicator({ name: 'sucursal_pregunta_18', formula: 'count-number' }),
        getIndicator({ name: 'sucursal_pregunta_19', formula: 'count-number' }),
        getIndicator({ name: 'sucursal_pregunta_20', formula: 'count-number' }),
        getIndicator({ name: 'sucursal_pregunta_28', formula: 'count-number' }),
        getIndicator({ name: 'sucursal_pregunta_29', formula: 'count-number' }),
        getSentimentIndicator('sentiment-sucursales', [13604]),
        getCountIndicator('survey-count-sucursales', [13604]),

        //acompañamiento
        getIndicator({ name: 'acomp_pregunta_2', formula: 'nps-5' }), //csat
        getIndicator({ name: 'acomp_pregunta_12', formula: 'nps-5' }), //atencion
        getIndicator({ name: 'acomp_pregunta_15', formula: 'nps-5' }), //facilidad
        getIndicator({ name: 'acomp_pregunta_17', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-acompanamiento', [9951]),
        getCountIndicator('survey-count-acompanamiento', [9951]),

        //prestamo Sofom
        getIndicator({ name: 'prestamo_sofom_encuesta_pregunta_2', formula: 'nps-5' }), //csat
        getIndicator({ name: 'prestamo_sofom_encuesta_pregunta_3', formula: 'nps-5' }), //facilidad
        getIndicator({ name: 'prestamo_sofom_encuesta_pregunta_13', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-pressofom', [10360]),
        getCountIndicator('survey-count-pressofom', [10360]),

        //EstadoCuenta
        getIndicator({ name: 'est_cuenta_pregunta_2', formula: 'nps-5' }), //csat
        getIndicator({ name: 'est_cuenta_pregunta_7', formula: 'nps-5' }), //facilidad
        getIndicator({ name: 'est_cuenta_pregunta_14', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-estadocuenta', [10353]),
        getCountIndicator('survey-count-estadocuenta', [10353]),
        
        //whatsapp alba
        getIndicator({ name: 'whatsapp_albapregunta_2', formula: 'nps-5' }), //csat
        getIndicator({ name: 'whatsapp_albapregunta_5', formula: 'nps-5' }), //facilidad
        getIndicator({ name: 'whatsapp_albapregunta_24', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-whatsappalba', [173215451]),
        getCountIndicator('survey-count-whatsappalba', [173215451]),
        
        //fuerza venta registros
        getIndicator({ name: 'fuerza_ventas_registros_encuesta_pregunta_3', formula: 'nps-5' }), //csat
        getIndicator({ name: 'fuerza_ventas_registros_encuesta_pregunta_9', formula: 'nps-5' }), //atencion
        getIndicator({ name: 'fuerza_ventas_registros_encuesta_pregunta_18', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-fuerzaventasregistros', [10362]),
        getCountIndicator('survey-count-fuerzaventasregistros', [10362]),
        
        //fuerza venta transpasos
        getIndicator({ name: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_7', formula: 'nps-5' }), //csat
        getIndicator({ name: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_10', formula: 'nps-5' }), //atencion
        getIndicator({ name: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_19', formula: 'nps-10' }), //nps
        getSentimentIndicator('sentiment-fuerzaventastraspasos', [10361]),
        getCountIndicator('survey-count-fuerzaventastraspasos', [10361]),
        

    ],
    sentiment: {
        indicators: {
            AutoservicioConAsesor: 'sentiment-autoserv',
            AutoservicioSinAsesor: 'sentiment-autoserv',
            AppMobile: 'sentiment-appmobile',
            AlbaChatbot: 'sentiment-albachatbot',
            CallCenter: 'sentiment-callcenter',
            ChatHumano: 'sentiment-chathumano',
            CuentasInst: 'sentiment-cuentasinst',
            CustodiaPresencial: 'sentiment-custpres',
            CustodiaTelefonica: 'sentiment-custtel',
            Sucursales: 'sentiment-sucursales',
            Acompanamiento: 'sentiment-acompanamiento',
            PrestamoSofom: 'sentiment-pressofom',
            EstadoCuenta: 'sentiment-estadocuenta',
            WhatsappAlba: 'sentiment-whatsappalba',
            FuerzaVentasRegistros: 'sentiment-fuerzaventasregistros',
            FuerzaVentasTraspasos: 'sentiment-fuerzaventastraspasos'

        },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'sentimentLocal',
            percentageLocal: 'percentageLocal'
        }
    },
    cxRay: {
        indicators: {
            AutoservicioConAsesor: [
                { order: 1, name: 'NPS', key: 'autoserv_con_asesor_pregunta_14', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'autoserv_con_asesor_pregunta_9', widgetType: 'gauge' },
            ],
            AutoservicioSinAsesor: [
                { order: 1, name: 'NPS', key: 'autoserv_sin_asesor_pregunta_21', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'autoserv_sin_asesor_pregunta_1', widgetType: 'gauge' },
            ],
            AppMobile: [
                { order: 1, name: 'NPS', key: 'app_pregunta_15', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'app_pregunta_4', widgetType: 'gauge' },
            ],
            AlbaChatbot: [
                { order: 1, name: 'NPS', key: 'albapregunta_18', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'albapregunta_2', widgetType: 'gauge' },
            ],
            CallCenter: [
                { order: 1, name: 'NPS', key: 'call_center_l_pregunta_35', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'call_center_l_pregunta_2', widgetType: 'gauge' },
            ],
            ChatHumano: [
                { order: 1, name: 'NPS', key: 'chat_humano_l_pregunta_15', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'chat_humano_l_pregunta_2', widgetType: 'gauge' },
            ],
            CuentasInst: [
                { order: 1, name: 'NPS', key: 'cuent_institucionales_pregunta_18', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'cuent_institucionales_pregunta_2', widgetType: 'gauge' },
            ],
            CustodiaPresencial: [
                { order: 1, name: 'NPS', key: 'cust_presencial_V2_pregunta_18', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'cust_presencial_V2_pregunta_2', widgetType: 'gauge' },
            ],
            CustodiaTelefonica: [
                { order: 1, name: 'NPS', key: 'cust_telefonica_pregunta_18', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'cust_telefonica_pregunta_2', widgetType: 'gauge' },
            ],
            Sucursales: [
                { order: 1, name: 'NPS', key: 'sucursal_pregunta_22', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'sucursal_pregunta_2', widgetType: 'gauge' },
            ],
            Acompanamiento: [
                { order: 1, name: 'NPS', key: 'acomp_pregunta_17', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'acomp_pregunta_2', widgetType: 'gauge' },
            ],
            PrestamoSofom: [
                { order: 1, name: 'NPS', key: 'prestamo_sofom_encuesta_pregunta_13', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'prestamo_sofom_encuesta_pregunta_2', widgetType: 'gauge' },
            ],
            EstadoCuenta: [
                { order: 1, name: 'NPS', key: 'est_cuenta_pregunta_14', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'est_cuenta_pregunta_2', widgetType: 'gauge' },
            ],
            
            WhatsappAlba: [
                { order: 1, name: 'NPS', key: 'whatsapp_albapregunta_24', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'whatsapp_albapregunta_2', widgetType: 'gauge' },
            ],
            
            FuerzaVentasRegistros: [
                { order: 1, name: 'NPS', key: 'fuerza_ventas_registros_encuesta_pregunta_18', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'fuerza_ventas_registros_encuesta_pregunta_3', widgetType: 'gauge' },
            ],
                        
            FuerzaVentasTraspasos: [
                { order: 1, name: 'NPS', key: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_19', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'fuerza_ventas_traspasos_encuesta_pregunta_v2_7', widgetType: 'gauge' },
            ],
        }
    },
    clusters: {
        AutoservicioConAsesor: {
            counters: { indicator: 'survey-count-autoserv' },
            detail: { indicators: getClusterIndicators('autoserv_con_asesor_pregunta_9', 'autoserv_con_asesor_pregunta_14', [11451]) },
        },
        AutoservicioSinAsesor: {
            counters: { indicator: 'survey-count-autoserv' },
            detail: { indicators: getClusterIndicators('autoserv_sin_asesor_pregunta_1', 'autoserv_sin_asesor_pregunta_21', [11451]) },
        },
        AppMobile: {
            counters: { indicator: 'survey-count-appmobile' },
            detail: { indicators: getClusterIndicators('app_pregunta_4', 'app_pregunta_15', [10352]) },
        },
        AlbaChatbot: {
            counters: { indicator: 'survey-count-albachatbot' },
            detail: { indicators: getClusterIndicators('app_pregunta_4', 'app_pregunta_15', [10351]) },
        },
        CallCenter: {
            counters: { indicator: 'survey-count-callcenter' },
            detail: { indicators: getClusterIndicators('call_center_l_pregunta_2', 'call_center_l_pregunta_35', [10373]) },
        },
        ChatHumano: {
            counters: { indicator: 'survey-count-chathumano' },
            detail: { indicators: getClusterIndicators('chat_humano_l_pregunta_2', 'chat_humano_l_pregunta_15', [10370]) },
        },
        CuentasInst: {
            counters: { indicator: 'survey-count-chathumano' },
            detail: { indicators: getClusterIndicators('cuent_institucionales_pregunta_2', 'cuent_institucionales_pregunta_18', [10366]) },
        },
        CustodiaPresencial: {
            counters: { indicator: 'survey-count-custpres' },
            detail: { indicators: getClusterIndicators('cust_presencial_V2_pregunta_2', 'cust_presencial_V2_pregunta_18', [10365]) },
        },
        CustodiaTelefonica: {
            counters: { indicator: 'survey-count-custtel' },
            detail: { indicators: getClusterIndicators('cust_telefonica_pregunta_2', 'cust_telefonica_pregunta_18', [10364]) },
        },
        Sucursales: {
            counters: { indicator: 'survey-count-sucursales' },
            detail: { indicators: getClusterIndicators('sucursal_pregunta_2', 'sucursal_pregunta_22', [13604]) },
        },
        Acompanamiento: {
            counters: { indicator: 'survey-count-acompanamiento' },
            detail: { indicators: getClusterIndicators('acomp_pregunta_2', 'acomp_pregunta_17', [9951]) },
        },
        PrestamoSofom: {
            counters: { indicator: 'survey-count-pressofom' },
            detail: { indicators: getClusterIndicators('prestamo_sofom_encuesta_pregunta_2', 'prestamo_sofom_encuesta_pregunta_13', [10360]) },
        },
        EstadoCuenta: {
            counters: { indicator: 'survey-count-estadocuenta' },
            detail: { indicators: getClusterIndicators('est_cuenta_pregunta_2', 'est_cuenta_pregunta_14', [10353]) },
        },
        WhatsappAlba: {
            counters: { indicator: 'survey-count-whatsappalba' },
            detail: { indicators: getClusterIndicators('whatsapp_albapregunta_2', 'whatsapp_albapregunta_24', [173215451]) },
        },
        FuerzaVentasRegistros: {
            counters: { indicator: 'survey-count-fuerzaventasregistros' },
            detail: { indicators: getClusterIndicators('fuerza_ventas_registros_encuesta_pregunta_3', 'fuerza_ventas_registros_encuesta_pregunta_18', [10362]) },
        },
        FuerzaVentasTraspasos: {
            counters: { indicator: 'survey-count-fuerzaventastraspasos' },
            detail: { indicators: getClusterIndicators('fuerza_ventas_traspasos_encuesta_pregunta_v2_7', 'fuerza_ventas_traspasos_encuesta_pregunta_v2_19', [10361]) },
        },                        

    },
    customerJourney: {

        AutoservicioConAsesor: (data: any) => {
            return getCJIndicatorItems(data, [
                'autoserv_con_asesor_pregunta_3',
                'autoserv_con_asesor_pregunta_5',
                'autoserv_con_asesor_pregunta_9',
                'autoserv_con_asesor_pregunta_14'])
        },
        AutoservicioSinAsesor: (data: any) => {
            return getCJIndicatorItems(data, [
                'autoserv_sin_asesor_pregunta_1',
                'autoserv_sin_asesor_pregunta_21'])
        },
        AppMobile: (data: any) => {
            return getCJIndicatorItems(data, [
                'app_pregunta_4',
                'app_pregunta_15'])
        },
        AlbaChatbot: (data: any) => {
            return getCJIndicatorItems(data, [
                'albapregunta_2',
                'albapregunta_4',
                'albapregunta_18'])
        },
        CallCenter: (data: any) => {
            return getCJIndicatorItems(data, [
                'call_center_l_pregunta_2',
                'call_center_l_pregunta_6',
                'call_center_l_pregunta_17',
                'call_center_l_pregunta_35'])
        },
        ChatHumano: (data: any) => {
            return getCJIndicatorItems(data, [
                'chat_humano_l_pregunta_2',
                'chat_humano_l_pregunta_3',
                'chat_humano_l_pregunta_15'])
        },
        CuentasInst: (data: any) => {
            return getCJIndicatorItems(data, [
                'cuent_institucionales_pregunta_2',
                'cuent_institucionales_pregunta_6',
                'cuent_institucionales_pregunta_18'])
        },
        CustodiaPresencial: (data: any) => {
            return getCJIndicatorItems(data, [
                'cust_presencial_V2_pregunta_2',
                'cust_presencial_V2_pregunta_6',
                'cust_presencial_V2_pregunta_18'])
        },
        CustodiaTelefonica: (data: any) => {
            return getCJIndicatorItems(data, [
                'cust_telefonica_pregunta_2',
                'cust_telefonica_pregunta_6',
                'cust_telefonica_pregunta_18'])
        },
        Sucursales: (data: any) => {
            return getCJIndicatorItems(data, [
                //'sucursal_pregunta_2',
                //'sucursal_pregunta_4',
                //'sucursal_pregunta_9',
                //'sucursal_pregunta_22'
                'sucursal_pregunta_18',
                'sucursal_pregunta_19',
                'sucursal_pregunta_16',
                'sucursal_pregunta_12',
                'sucursal_pregunta_13',
                'sucursal_pregunta_14',
                'sucursal_pregunta_15',
                'sucursal_pregunta_20',
                //'sucursal_pregunta_28',
                //'sucursal_pregunta_29'
            ])
        },
        Acompanamiento: (data: any) => {
            return getCJIndicatorItems(data, [
                'acomp_pregunta_2',
                'acomp_pregunta_12',
                'acomp_pregunta_15',
                'acomp_pregunta_17'])
        },
        PrestamoSofom: (data: any) => {
            return getCJIndicatorItems(data, [
                'prestamo_sofom_encuesta_pregunta_2',
                'prestamo_sofom_encuesta_pregunta_3',
                'prestamo_sofom_encuesta_pregunta_13'])
        },
        EstadoCuenta: (data: any) => {
            return getCJIndicatorItems(data, [
                'est_cuenta_pregunta_2',
                'est_cuenta_pregunta_7',
                'est_cuenta_pregunta_14'])
        },

        WhatsappAlba: (data: any) => {
            return getCJIndicatorItems(data, [
                'whatsapp_albapregunta_2',
                'whatsapp_albapregunta_5',
                'whatsapp_albapregunta_24'])
        },

        FuerzaVentasRegistros: (data: any) => {
            return getCJIndicatorItems(data, [
                'fuerza_ventas_registros_encuesta_pregunta_3',
                'fuerza_ventas_registros_encuesta_pregunta_9',
                'fuerza_ventas_registros_encuesta_pregunta_18'])
        },

        FuerzaVentasTraspasos: (data: any) => {
            return getCJIndicatorItems(data, [
                'fuerza_ventas_traspasos_encuesta_pregunta_v2_7',
                'fuerza_ventas_traspasos_encuesta_pregunta_v2_10',
                'fuerza_ventas_traspasos_encuesta_pregunta_v2_19'])
        }                      

    }
}
