
import logoHa from '@assets/logos/ha-logo.png'
import logoHaSmall from '@assets/logos/ha-logo-small.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import { haSurveyResponsesTableColumns } from './ha/extraConfig'
import { omoikaneHA } from '../omoikane/omoikaneHA'
import deafultHelpDefinition from '../help/defaultHelp'


export const haConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://ha-backend-voc.limetropy.com/api/',
    //vocBackendUrl: 'http://localhost:9000/api/',
    lcmBackendUrl: '',
    publicUrl: '/voc',
    logoSmall: logoHaSmall,
    logo: logoHa,
    filtersFromDateSubstract: 1,
    filtersToDateSubstract: 0,
    decimals: undefined,
    supportsInternationalization: false,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    menuDashboards: [DASHBOARDS.DEFAULT, DASHBOARDS.SOCIAL_LISTENING],
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    msExcelReportExt: 'xls',
    geosRootId: 0,
    logicalsRootId: 0,
    criticalMomentsRootId: 29,
    criticalMomentFilterType: 'select',
    actionPlans: {
        geoLeafLevel: '',
        useWorstTags: true
    },
    omoikane: omoikaneHA,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.ha,
        default: DASHBOARDS_CONFIGS.ha[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponses: {
        url: 'survey-responses',
        type: 'post-context',
        tableColumns: haSurveyResponsesTableColumns,
        tabs: [SurveyResponsesTab.ANSWERED],
    },
    alerts: {},
    help: deafultHelpDefinition,
    pageSize: 100,
    questionsCustomerName:['durante_a_pregunta_2', 'posterior_a_pregunta_1', 'durante_pregunta_2']
}
