import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_PIE_COLORS, commonWidgets, getProfuturoFacilidad, getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoSatisfaccion, getProfuturoCustomerJourney, PRODUTURO_PIE_SINO_COLORS, PRODUTURO_SCALE_4_COLORS, PROFUTURO_ORANGE, PROFUTURO_RED_1, PROFUTURO_GREEN_1 } from "../commonWidgetsProfuturo";

export default {
    title: 'Acompañamiento Dashboard',
    description: 'Acompañamiento Dashboard',
    backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI) => [
        {
            title: 'Principales Indicadores',
            position: [0, 0],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getProfuturoNpsAcumulado({
            position: [0, 1],
            size: [1, 2],
            indicator: 'albapregunta_18'
        }),
        getProfuturoNpsPorMes({
            position: [1, 1],
            size: [1, 2],
            indicator: 'albapregunta_18',
        }),
        getProfuturoSatisfaccion({
            position: [2, 1],
            size: [1, 2],
            indicator: 'albapregunta_2',
            helpText: '¿qué tan satisfecho estás con tu interacción con Alba?'
        }),
        getProfuturoFacilidad({
            position: [3, 1],
            size: [1, 2],
            indicator: 'albapregunta_4',
            helpText: '¿Qué tan fácil te resultó interactuar con Alba a través de WhatsApp?'
        }),
        {
            title: 'Motivadores y quiebres',
            position: [0, 3],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Satisfacción: clientes insatisfechos',
            position: [0, 4],
            size: [4, 2],
            indicator: 'albapregunta_3',
            helpText: '¿Por qué calificaste de esa manera tu interacción con Alba?',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Facilidad: clientes satisfechos',
            position: [0, 6],
            size: [2, 2],
            indicator: 'albapregunta_7',
            helpText: '¿Por qué fue fácil?',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 10,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Facilidad: clientes neutros',
            position: [2, 6],
            size: [2, 2],
            indicator: 'albapregunta_6',
            helpText: '¿En qué podríamos mejorar para que la interacción con Alba sea fácil?',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 10,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Facilidad: clientes insatisfechos',
            helpText: ' Señalaste que la interacción con Alba no fue fácil, ¿por qué motivos?',
            position: [0, 8],
            size: [2, 2],
            indicator: 'albapregunta_5',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 10,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMediumSiNo',
            title: 'Efectividad',
            helpText: '¿Pudiste resolver todo lo que necesitabas?',
            position: [2, 8],
            size: [2, 2],
            indicator: 'albapregunta_8',
            extraConfigs: {
              colors: {
                3: PROFUTURO_ORANGE,
                2: PROFUTURO_RED_1,
                1: PROFUTURO_GREEN_1,
              }
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Efectividad: consultas realizadas',
            helpText: '¿Qué consulta/trámite no pudiste resolver a través de Alba?',
            position: [0, 10],
            size: [2, 2],
            indicator: 'albapregunta_9',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 10,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Efectividad: consultas no resueltas',
            helpText: '¿Con qué canales interactuaste antes de llegar con Alba en WhatsApp? ',
            position: [2, 10],
            size: [2, 2],
            indicator: 'albapregunta_10',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 10,
                center: ['27%', '55%'],
            }
        }),
        getProfuturoCustomerJourney({
            id: 'cj-alba',
            position: [0, 12],
            size: [3, 2],
            indicators: [{ name: 'albapregunta_2', formula: 'count-number' },
            { name: 'albapregunta_4', formula: 'count-number' },
            { name: 'albapregunta_18', formula: 'nps-10' }
            ],
            title: 'Customer Journey: Top Two Box',
            
        }),
        {
            title: 'Atributos de experiencia',
            position: [0, 14],
            size: [4, 1],
            type: 'label',
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'El tiempo que llevó realizar mis consultas / trámites fue el apropiado',
            position: [0, 15],
            size: [4, 1],
            helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
            indicator: 'albapregunta_12'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'En general, Alba comprendió las frases o preguntas que le realicé.',
            position: [0, 16],
            size: [4, 1],
            helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
            indicator: 'albapregunta_13'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La conversación fue fluida durante la interacción.',
            position: [0, 17],
            size: [4, 1],
            helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
            indicator: 'albapregunta_14'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'Me ofreció soluciones adecuadas a mis necesidades.',
            position: [0, 18],
            size: [4, 1],
            helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
            indicator: 'albapregunta_15'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'Me ahorró la necesidad de realizar consultas/trámites a través de otros canales.',
            position: [0, 19],
            size: [4, 1],
            helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
            indicator: 'albapregunta_16'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'Me resulta un canal seguro y confiable.',
            position: [0, 20],
            size: [4, 1],
            helpText: 'Pensando en tu interacción con Alba, ¿qué tan de acuerdo te encuentras con las siguientes frases?',
            indicator: 'albapregunta_17'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'Análisis de Sentimientos',
            position: [0, 21],
            size: [4, 4],
            indicator: 'SENTIMENT'
        }),
    ]
} as DasboardConfig
